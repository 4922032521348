$(function() {
    // on load of the page: switch to the currently selected tab
    var hash = window.location.hash;
    $('.smart-tabs a[href="' + hash + '"]').tab('show');

    if(window.location.hash == '') {
        window.location.hash = window.location.hash + '#_';
    }
    var hash = window.location.hash.split('#')[1];
    var prefix = '_';
    var hpieces = hash.split('/');

    for (var i=0;i<hpieces.length;i++) {
        var domelid = hpieces[i].replace(prefix,'');
        var domitem = $('a[href=#' + domelid + '][data-toggle=tab]');
        if (domitem.length > 0) {
            domitem.tab('show');
        }
    }
    $('a[data-toggle=tab]').on('shown.bs.tab', function (e) {
        if ($(this).hasClass('nested')) {
            var nested = window.location.hash.split('/');
            window.location.hash = nested[0] + '/' + e.target.hash.split('#')[1];
        } else {
            window.location.hash = e.target.hash.replace('#', '#' + prefix);
        }
    });

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

    try {
        $('.summernote').summernote({
            placeholder: 'Content here...',
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'italic', 'underline', 'clear']],
                ['fontsize', ['fontsize']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'hr']],
                ['view', ['codeview']],
            ],
            height: 300,
            minHeight: 300,
            maxHeight: 1000
        });
    } catch(err){
        console.log("Summernote not loaded: " + err);
    }
});

function copyToClipboard(element) {
    $("body").append("<input type='text' id='temp' style='position:absolute;opacity:0;'>");
    $("#temp").val($(element).text()).select();
    document.execCommand("copy");
    $("#temp").remove();
    toastr.success('Item copied to clipboard.')
}